@tailwind base;
@tailwind components;
@tailwind utilities;


/* Buttons */
.btn {
  @apply block bg-yellow-200 px-4 py-2 rounded
}

input[type=range] {
  width: 100%;
  height: 2px;
  -webkit-appearance: none;
  background: #B1B1B1;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
}
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 10px;
  height: 15px;
  border-radius: 2px;
  background: #3A3A3A;
  cursor: grab;
}